<template>
  <div class="alert-container" :class="[type]">
    <Icon :name="iconName" />
    <div class="alert">
      <slot />
    </div>
  </div>
</template>

<script>
import Icon from "@/components/icons/Icon";
export default {
  name: "Alert",
  components: { Icon },
  props: {
    type: { type: String, default: "info" },
  },
  computed: {
    iconName() {
      switch (this.type) {
        case "danger":
          return "x-circle";
        case "warning":
          return "alert";
        case "info":
        default:
          return "info";
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/colors/by-class";

.alert-container {
  @extend .color-by-class;
  display: flex;
  align-items: center;
  gap: 1em;
  width: 100%;
  height: fit-content;
  border: 1px solid var(--bg-color);
  background-color: color-mix(in srgb, var(--bg-color) 5%, transparent);
  border-radius: 0.6em;
  padding: 0.8em;

  .icon {
    height: 1.2em;
    width: 1.2em;
    color: var(--bg-color);
  }

  .alert {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    color: var(--bg-color);
    margin: 0;
    padding: 0;

    & > * {
      margin: 0;
    }

    p {
      font-size: 1em !important;
    }
  }
}
</style>
